import React from 'react'
import { Link } from 'gatsby'
import { Button } from '@foundation-lib/ui'
import styled from 'styled-components'
import { Cart, useCartItemList } from '@foundations-of-grace-pub/checkout'
// import Ads from './Ads'

const OfflineOrder = styled(({ className }) => (
  <p className={className}>
    To order offline or to enquire about bulk (24+ copies) pricing,
    <br />
    call us at <b>(740) 831-4249</b> or{' '}
    <Link to="/contact">send us an e-mail with our contact form</Link>.
  </p>
))`
  margin: 2px 0px;
  font-size: 14px;
  text-align: right;
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const CheckoutButton = () => {
  const { getSubtotal } = useCartItemList()
  const checkoutEnabled = getSubtotal() > 0

  return (
    <Link to={checkoutEnabled ? '/checkout' : null}>
      <Button size="large" disabled={!checkoutEnabled}>
        Checkout
      </Button>
    </Link>
  )
}

const CartPageBody = () => (
  <>
    <h1>Shopping cart</h1>
    <Cart />
    <br />
    <BottomRow>
      <CheckoutButton />
      <OfflineOrder />
    </BottomRow>
    {/* <Ads /> */}
  </>
)

export default CartPageBody
