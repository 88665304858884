import React from 'react'
import { StandardPage } from 'src/features/templates'
import SEO from '../../../components/seo'
import CartPageBody from './CartPageBody'

const GatsbyCartPage = () => (
  <StandardPage>
    <SEO title="Cart" />
    <CartPageBody />
  </StandardPage>
)

export default GatsbyCartPage
